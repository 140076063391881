import React from "react";
import FeaturedItems from "../FeaturedItems";

import Hero from "../Jumbotron";

function HomePage() {
  return (
    <>
      <Hero />
      <FeaturedItems />
    </>
  );
}

export default HomePage;
