import React from "react";
function FooterNav() {
  return (
    <footer className="p-3 text-center" style={{ marginTop: "6rem" }}>
      &copy; 1994 - 2020 Excellent Engineering Enterprises
    </footer>
  );
}

export default FooterNav;
