import {
  faEnvelope,
  faMapMarker,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import whatsapp from "../../images/whatsapp.svg";

function ContactPage() {
  return (
    <>
      <h2 className="text-center font-weight-bold fs-32 mb-5 mt-4">
        Contact <span className="text-danger">Us</span>
      </h2>
      <Container fluid>
        <Row id="contact-cards">
          <Col md="3" sm="12">
            <Card className="text-center mb-4 border-none contact-card--1">
              <CardBody className="card-shadow br-8">
                <CardTitle className="featured-card-title px-3">
                  <FontAwesomeIcon icon={faPhone} size="3x" color="#fff" />
                </CardTitle>
                <a
                  className="text-white fs-20 d-block"
                  href="tel:+918041149405"
                >
                  080-41149405
                </a>
                <a className="text-white fs-20" href="tel:+918022242581">
                  080-22242581
                </a>
              </CardBody>
            </Card>
          </Col>
          <Col md="3" sm="12">
            <Card className="text-center mb-4 border-none contact-card--2">
              <a
                href="https://goo.gl/maps/4HspwNUUN7kHsy3K8"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CardBody className="card-shadow br-8">
                  <CardTitle className="featured-card-title px-3">
                    <FontAwesomeIcon
                      icon={faMapMarker}
                      size="3x"
                      color="#fff"
                    />
                  </CardTitle>
                  <p className="text-white">
                    Excellent Engineering Enterprises, <br />
                    #73, N.R. Road, Bangalore - 560 002.
                  </p>
                </CardBody>
              </a>
            </Card>
          </Col>
          <Col md="3" sm="12">
            <Card className="text-center mb-4 border-none contact-card--3">
              <a href="mailto:info@eeeblr.in?cc=eee.hardware@gmail.com">
                <CardBody className="card-shadow br-8">
                  <CardTitle className="featured-card-title px-3">
                    <FontAwesomeIcon icon={faEnvelope} size="3x" color="#fff" />
                  </CardTitle>
                  <p className="text-white fs-28">info@eeeblr.in</p>
                </CardBody>
              </a>
            </Card>
          </Col>
          <Col md="3" sm="12">
            <Card className="text-center mb-4 border-none contact-card--4">
              <a
                href="https://api.whatsapp.com/send?phone=919845094586"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CardBody className="card-shadow br-8">
                  <CardTitle className="featured-card-title px-3">
                    <img
                      src={whatsapp}
                      style={{
                        width: "6rem",
                      }}
                      alt=""
                    />
                  </CardTitle>
                  <p className="text-white pt-2 fs-28">WhatsApp</p>
                </CardBody>
              </a>
            </Card>
          </Col>
        </Row>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15552.532198092087!2d77.581006!3d12.963337!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd3412c520ec91ead!2sexcellent%20engineering%20enterprises!5e0!3m2!1sen!2sin!4v1599651509301!5m2!1sen!2sin"
          className="border-none mt-5"
          title="googlemap"
          width="100%"
          height="450"
          frameBorder="0"
          allowFullScreen="true"
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </Container>
    </>
  );
}

export default ContactPage;
