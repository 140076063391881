import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AboutUsPage from "../containers/About";
import ContactPage from "../containers/Contact";
import FooterNav from "../containers/Footer";
import HeaderNav from "../containers/Header";
import HomePage from "../containers/Home";
import ProductsPage from "../containers/Products";
import ContactButton from "./ContactButton";

function App() {
  return (
    <>
      <HeaderNav />
      <ContactButton />
      <Router>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/about">
            <AboutUsPage />
          </Route>
          <Route path="/contact">
            <ContactPage />
          </Route>
          <Route path="/products">
            <ProductsPage />
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
        </Switch>
      </Router>
      <FooterNav />
    </>
  );
}

export default App;
