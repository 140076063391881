import React, { useState } from "react";
import {
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndustry } from "@fortawesome/free-solid-svg-icons";
import logo from "../../images/logo.svg";
const data = require("../../data/categories.json");

function HeaderNav() {
  const [isOpen, setIsOpen] = useState(false);
  var categories = data.categories;

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <Navbar color="faded" light expand="md" className="bb-light">
        <NavbarBrand href="/">
          <img
            src={logo}
            width="50"
            height="50"
            alt="Excellent Engineering Enterprises"
          />
          <span className="d-md-inline fs-sm-14">
            Excellent Engineering Enterprises
          </span>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem className="px-md-4 pl-sm-0 pt-2">
              <NavLink href="/">Home</NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar className="pt-2">
              <DropdownToggle nav caret>
                Products
              </DropdownToggle>
              <DropdownMenu right className="pl-3">
                {categories.map((catName, i) => (
                  <a
                    key={i}
                    href={`/products#${catName._id}`}
                    className="d-block py-3"
                  >
                    <FontAwesomeIcon
                      icon={faIndustry}
                      className="text-primary mr-3 fs-16"
                    />
                    {catName.categoryName}
                  </a>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem className="px-md-4 pl-sm-0 pt-2">
              <NavLink href="/about">About Us</NavLink>
            </NavItem>
            <NavItem className="pt-2">
              <NavLink href="/contact">Contact</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      <div>
        <table className="header-sub">
          <tbody>
            <tr>
              <td>
                <hr />
              </td>
              <td>
                One stop shop for all your industrial and project requisites.
              </td>
              <td>
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default HeaderNav;
