import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
const data = require("../../data/categories.json");
const brandLogos = require("../../data/brands.json");

const FeaturedItems = (props) => {
  var categories = data.categories;
  var brandLogosData = brandLogos.brands;

  return (
    <>
      <section className="col-12 mb-5 featured-items-bg text-center">
        <h2 className="pb-5">Featured Categories</h2>
        {categories.map((catNames, i) => (
          <Card
            key={i}
            className="text-center mb-5 mr-5 border-none alt-bg d-inline-block br-3r"
          >
            <a href={`/products#${catNames._id}`}>
              <CardBody className="card-shadow br-3r">
                <CardTitle className="featured-card-title px-5 mb-0">
                  {catNames.categoryName}
                </CardTitle>
                {/* <a href="/products">
                  <Button outline color="light" className="my-3 featured-btn">
                    Know More{" "}
                  </Button>
                </a> */}
              </CardBody>
            </a>
          </Card>
        ))}
      </section>
      <section className="col-12 top-brands text-center">
        <h2 className="pb-5">Featured Brands</h2>
        {brandLogosData.map((blogo, i) => (
          <img src={blogo.img} className="mr-5 mb-5" alt="" />
        ))}
      </section>
    </>
  );
};

export default FeaturedItems;
