import { faRocket, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Card,
  CardGroup,
  CardImg,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
const data = require("../../data/categories.json");

function ProductsPage() {
  var categories = data.categories;

  const [search, setSearch] = useState(null);

  const searchSpace = (event) => {
    let search = event.target.value;
    setSearch(search);
  };

  return (
    <Container fluid className="my-5">
      <Row>
        <Col md="3" sm="12" className="pb-5 overflow-auto d-none d-md-block">
          {categories.map((catName, i) => (
            <>
              <h5 key={i}>
                <FontAwesomeIcon
                  icon={faRocket}
                  className="text-secondary mr-2 fs-16"
                />
                {catName.categoryName}
              </h5>
              <Nav vertical>
                {catName.sub_categories.map((subCat, i) => (
                  <NavItem key={i}>
                    <NavLink href={`#${subCat._id}`}>
                      {subCat.subCategoryName}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </>
          ))}
        </Col>
        <Col md="9" sm="12">
          <Row>
            <input
              id="searchfield"
              className="mr-3 px-3 py-2 mb-4 ml-auto w-15r"
              type="text"
              placeholder="Search products..."
              onChange={(e) => searchSpace(e)}
            />
            <FontAwesomeIcon icon={faSearch} className="productsSearchIcon" />
          </Row>
          <Row className="no-gutters">
            {categories.map((category, i) => (
              <>
                {category.sub_categories
                  .filter((subCategory) => {
                    if (search == null) return "subCategory";
                    else if (
                      subCategory.subCategoryName
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return subCategory;
                    }
                  })
                  .map((subCategory, i) => (
                    <>
                      <h3
                        key={i}
                        id={subCategory._id}
                        className="col-12 mb-5 pb-3"
                        style={{ borderBottom: "1px solid #d7d7d7" }}
                      >
                        {subCategory.subCategoryName}
                      </h3>
                      <Col sm="12" key={i}>
                        <CardGroup className="mb-5">
                          {subCategory.items
                            .filter((item) => {
                              if (search == null) return item;
                              else if (
                                item.itemName
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              ) {
                                return item;
                              }
                            })
                            .map((item, i) => {
                              return (
                                <Card
                                  key={i}
                                  className="col-sm-12 col-md-3 br-8 border-none mb-4"
                                >
                                  <CardImg
                                    className="obj-fit--contain"
                                    width="100%"
                                    height="150px"
                                    // src={`${API_URI}${item.image.url}`}
                                    src={`${item.image.url}`}
                                    alt=""
                                  />
                                  <p className="text-center my-3 fs-18">
                                    {item.itemName}
                                  </p>
                                </Card>
                              );
                            })}
                        </CardGroup>
                      </Col>
                    </>
                  ))}
              </>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default ProductsPage;
