import React from "react";
import HeroImageLarge from "../../images/AboutUsBanner.png";
import HeroImageMobile from "../../images/AboutUsBannerMobile.png";

function AboutUsPage() {
  return (
    <>
      <p className="text-center font-weight-bold my-4 fs-28">
        About <span className="text-danger">Us</span>
      </p>
      <div className="col-md-9 col-sm-12 mx-auto mt-3 mb-5">
        <div className="p-0 mb-4">
          <picture>
            <source srcSet={HeroImageLarge} media="(min-width: 1200px)" />
            <img src={HeroImageMobile} alt="" width="100%" height="400px" />
          </picture>
        </div>
        <div className="col-md-10 col-sm-12 mx-auto my-5">
          <h2 className="mb-4">Who We Are</h2>
          <p className="abt-para">
            Excellent Engineering Enterprises is renowned not only for its
            genuine quality products, but also for its commitment towards
            providing best service to its customers.
          </p>
          <p className="abt-para">
            What sets us apart from the others is its ability to offer products
            in a wide variety to customers. With over 25 years of experience,
            the firm and its people, as a whole, have acquired in depth
            knowledge of Mechanical, Civil, Electrical, Construction and
            Structural Engineering and are hence able to understand the needs of
            the customer.
          </p>
        </div>

        <div className="dropdown-divider col-sm-10 mb-5 mx-auto"></div>

        <div className="col-md-10 col-sm-12 mx-auto my-5">
          <h2 className="mb-4">Our Approach</h2>
          <p className="abt-para">
            Having been actively rendering reliable, resourceful, reasonable and
            efficient services professionally since last 25 years. We offer a
            host of items from off the shelf stocks which covers a wide range of
            products and make it available to our customers in a short span of
            time.
          </p>
          <p className="abt-para">
            We are able to achieve a role of being a vital source for offering
            range of products due to our valuable customers without whose
            patronage we could not have come up so fast.
          </p>
        </div>
      </div>
    </>
  );
}

export default AboutUsPage;
