import React from "react";
import { Button } from "reactstrap";

const ContactButton = () => {
  return (
    <Button color="warning" size="lg" className="contactUsBtn">
      <a href="/contact">Contact Us</a>
    </Button>
  );
};

export default ContactButton;
