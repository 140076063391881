import React from "react";
import { Jumbotron } from "reactstrap";
import HeroImageLarge from "../../images/HomePageBanner.png";
import HeroImageMobile from "../../images/HomePageBannerMobile.png";

const Hero = (props) => {
  return (
    <>
      <Jumbotron fluid className="p-0 mb-0">
        <picture>
          <source srcSet={HeroImageLarge} media="(min-width: 1200px)" />
          <img src={HeroImageMobile} alt="" width="100%" height="400px" />
        </picture>
      </Jumbotron>
    </>
  );
};

export default Hero;
